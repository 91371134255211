import mixpanel from "mixpanel-browser";
mixpanel.init("7bc3f0df52a4ae37f2f75a32a6e47c05");

const env_check = process.env.REACT_APP_CUSTOM_ENV === "production";

console.log("ENV_CHECK: ", env_check, process.env.REACT_APP_CUSTOM_ENV, JSON.stringify(process.env));

const actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  reset: () => {
    if (env_check) mixpanel.reset();
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
